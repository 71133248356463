/*
* @Author: Jens K
* @Date:   2018-03-30 10:18:28
* @Last Modified by:   Jens K
* @Last Modified time: 2018-07-09 10:56:00
*/

(function(w, undefined) {

    var d = w.document,
        init = function() {

            nav();
            mobileNav();
            subNav();
            search();
            dropdown();

        },
        resize = function() {

            nav();

        },
        nav = function() {

            var scrolled = 'page-scrolled',
                scrolledUp = 'page-scrolled-up',
                nav = document.querySelector('.main-nav'),
                navHeight = nav.offsetHeight || 0,
                subnav = document.querySelector('.sub-nav'),
                breadcrumb = document.querySelector('.breadcrumb'),
                lastScrollPosition = 0,
                w = window.innerWidth;

            checkPos();
            window.onscroll = function() { checkPos(); }

            function checkPos() {

                var newScrollPosition = window.pageYOffset;
                var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

                if (scrollTop < 38) {

                    nav.classList.remove(scrolled);
                    nav.classList.remove(scrolledUp);

                    if (subnav) {
                        if (w > 1023 && nav.dataset.collapsed == 'true') { subnav.classList.remove(scrolled); }
                        else if (w > 767 && nav.dataset.collapsed == 'false') { subnav.classList.remove(scrolled); }
                    }

                }

                if (newScrollPosition < lastScrollPosition){

                    if (scrollTop > 38) {

                        nav.classList.add(scrolledUp);

                        if (subnav) {
                            if (w > 1023 && nav.dataset.collapsed == 'true') { subnav.classList.add(scrolledUp); }
                            else if (w > 767 && nav.dataset.collapsed == 'false') { subnav.classList.add(scrolledUp); }
                        }

                    }

                } else {

                    nav.classList.remove(scrolledUp);

                    if (subnav) {
                        if (w > 1023 && nav.dataset.collapsed == 'true') { subnav.classList.remove(scrolledUp); }
                        else if (w > 767 && nav.dataset.collapsed == 'false') { subnav.classList.remove(scrolledUp); }
                    }

                    if (scrollTop > 38) {

                        nav.classList.add(scrolled);

                        if (subnav) {
                            if (w > 1023 && nav.dataset.collapsed == 'true') { subnav.classList.add(scrolled); }
                            else if (w > 767 && nav.dataset.collapsed == 'false') { subnav.classList.add(scrolled); }
                        }

                    }

                }

                lastScrollPosition = newScrollPosition;

            }

            if (w > 767 && nav.dataset.collapsed == 'false') {
                addCss(':root { --nav-height: ' + navHeight + 'px; }');
            } else if (w > 1023 && nav.dataset.collapsed == 'true') {
                addCss(':root { --nav-height: ' + navHeight + 'px; }');
            } else {
                addCss(':root {}');
            }

            function addCss(rule) {

                var css = document.createElement('style');
                css.type = 'text/css';

                if (css.styleSheet) {
                    css.styleSheet.cssText = rule;
                } else {
                    css.appendChild(document.createTextNode(rule));
                }
                document.getElementsByTagName("head")[0].appendChild(css);

            }

        },
        mobileNav = function() {

            var trigger = document.querySelector('.mobile-nav__trigger'),
                mainNav = document.querySelectorAll('.main-nav__local-nav a'),
                navWrapper = document.querySelector('.main-nav'),
                nav = document.querySelector('.main-nav__content'),
                back = document.querySelector('.main-nav__back'),
                subNav = document.querySelector('.sub-nav'),
                currentActive = document.querySelector('.main-nav__local-nav a.active'),
                activeClass = 'nav--open',
                activeClasses = ['sub-nav--open', 'active', 'sub-nav__item__visible'];

            if(!trigger) { return; }

            trigger.addEventListener('click', function(e) {

                e.preventDefault();
                toggleNav();

                require(["bodyScrollLock"], function(bodyScrollLock) {

                    var bodyScroll = bodyScrollLock;
                    bodyScroll.disableBodyScroll(nav);

                });

            });

            back.addEventListener('click', function(e) {

                e.preventDefault();
                toggleNav();

                require(["bodyScrollLock"], function(bodyScrollLock) {

                    var bodyScroll = bodyScrollLock;
                    bodyScroll.enableBodyScroll(nav);

                });

            });

            toggleNav = function(){

                trigger.classList.toggle(activeClass);
                nav.classList.toggle(activeClass);
                back.classList.toggle(activeClass);

                if (subNav.classList.contains(activeClasses[0])) {

                    mainNav.forEach(function(m){ m.classList.remove(activeClasses[1]); });

                    subNav.classList.remove(activeClasses[0]);
                    navWrapper.classList.remove(activeClasses[0]);
                    if (currentActive) { currentActive.classList.add(activeClasses[1]); }

                }

            }

        },
        subNav = function() {

            var nav = document.querySelector('.main-nav'),
                subnav = document.querySelector('.sub-nav'),
                trigger = document.querySelectorAll('[data-subnav]'),
                mainNav = document.querySelectorAll('.main-nav__local-nav a'),
                targetList = document.querySelectorAll('.sub-nav__item'),
                currentActive = document.querySelector('.main-nav__local-nav a.active'),
                mobileClose = document.querySelector('.sub-nav__mobile-close'),
                activeClasses = ['sub-nav--open', 'active', 'sub-nav__item__visible'],
                w = window.innerWidth,
                target;

            for (var i = 0, len = trigger.length; i < len; i++) {

                trigger[i].addEventListener('click', function(e) {

                    target = this.dataset.subnav;
                    e.preventDefault();

                    if (!this.classList.contains(activeClasses[1])) {

                        mainNav.forEach(function(m){ m.classList.remove(activeClasses[1]); });
                        targetList.forEach(function(t){ t.classList.remove(activeClasses[2], activeClasses[0]); });

                        if (w > 1023 && nav.dataset.collapsed == 'true') { nav.classList.add(activeClasses[0]); }
                        else if (w > 767 && nav.dataset.collapsed == 'false') { nav.classList.add(activeClasses[0]); }

                        subnav.classList.add(activeClasses[0]);
                        this.classList.add(activeClasses[1]);

                        document.querySelector(target).classList.add(activeClasses[2]);

                        setTimeout(function(){
                            document.querySelector(target).classList.add(activeClasses[0]);
                        }, 150);

                    } else {

                        this.classList.remove(activeClasses[1]);
                        nav.classList.remove(activeClasses[0]);
                        subnav.classList.remove(activeClasses[0]);
                        if (currentActive) { currentActive.classList.add(activeClasses[1]); }

                        document.querySelector(target).classList.remove(activeClasses[2], activeClasses[0]);

                    }

                }, false);

            }

            if(mobileClose) {

                mobileClose.addEventListener('click', function(e) {

                    e.preventDefault();

                    mainNav.forEach(function(m){ m.classList.remove(activeClasses[1]); });

                    this.classList.remove(activeClasses[1]);
                    nav.classList.remove(activeClasses[0]);
                    subnav.classList.remove(activeClasses[0]);
                    if (currentActive) { currentActive.classList.add(activeClasses[1]); }

                }, false);

            }

            window.addEventListener('click', function(e) {

                var checkNav = getClosest(e.target, '.main-nav');

                if (!checkNav && nav.classList.contains(activeClasses[0])) {

                    mainNav.forEach(function(m){ m.classList.remove(activeClasses[1]); });
                    targetList.forEach(function(t){ t.classList.remove(activeClasses[2], activeClasses[0]); });

                    nav.classList.remove(activeClasses[0]);
                    subnav.classList.remove(activeClasses[0]);
                    if (currentActive) { currentActive.classList.add(activeClasses[1]); }

                }

            }, false);

            var getClosest = function (elem, selector) {
                for ( ; elem && elem !== document; elem = elem.parentNode ) {
                    if ( elem.matches( selector ) ) return elem;
                }
                return null;
            };

        },
        search = function() {

            var sTrigger = document.querySelectorAll('.search__trigger'),
                sClose = document.querySelectorAll('.search__close'),
                nav = document.querySelector('.main-nav'),
                sInput = document.querySelector('.search-widget__input input'),
                activeClass = 'main-nav__search-open', animationClass = 'main-nav__search-opening';

            if (!sTrigger.length) { return; }

            [].forEach.call(sTrigger, function(_sTrigger) {

                _sTrigger.addEventListener('click', function(e){

                    e.preventDefault();

                    if (!nav.classList.contains(activeClass)) {

                        nav.classList.add(activeClass);

                        setTimeout(function() {

                            nav.classList.add(animationClass);
                            sInput.focus();

                        }, 50);

                    }

                }, false);

            });

            [].forEach.call(sClose, function(_sClose) {

                _sClose.addEventListener('click', function(e){

                    e.preventDefault();

                    if (nav.classList.contains(activeClass)) {

                        nav.classList.remove(animationClass);

                        setTimeout(function() {

                            nav.classList.remove(activeClass);

                        }, 350);

                    }

                }, false);

            });

        },
        dropdown = function() {

            var nav = document.querySelector('.main-nav'),
                trigger = document.querySelectorAll('[data-dropdown]'),
                triggers = document.querySelectorAll('.main-nav__service-nav a.service-nav__sub-nav__trigger'),
                targetList = document.querySelectorAll('.dropdown'),
                currentActive = document.querySelector('.main-nav__service-nav a.active'),
                activeClasses = ['active', 'dropdown--open', 'dropdown--active'],
                back = document.querySelector('.main-nav__back'),
                mobileClose = document.querySelector('.service-nav__sub-nav__mobile-close'),
                native = document.querySelector('select.language__select'),
                target;

            for (var i = 0, len = trigger.length; i < len; i++) {

                trigger[i].addEventListener('click', function(e) {

                    target = this.dataset.dropdown;
                    e.preventDefault();
                    e.stopPropagation();

                    if (!this.classList.contains(activeClasses[0])) {

                        triggers.forEach(function(m){ m.classList.remove(activeClasses[0]); });
                        targetList.forEach(function(t){ t.classList.remove(activeClasses[1], activeClasses[2]); });

                        this.classList.add(activeClasses[0]);

                        document.querySelector(target).classList.add(activeClasses[1]);

                        setTimeout(function(){
                            document.querySelector(target).classList.add(activeClasses[2]);
                        }, 50);

                    } else {

                        this.classList.remove(activeClasses[0]);
                        if (currentActive) {
                            currentActive.classList.add(activeClasses[0]);
                        }

                        document.querySelector(target).classList.remove(activeClasses[1], activeClasses[2]);

                    }

                }, false);

            }

            if (native) {

                native.addEventListener('change', function(e){

                    window.location.href = this.value;

                }, false);

            }

            if(mobileClose) {

                mobileClose.addEventListener('click', function(e) {

                    triggers.forEach(function(m){ m.classList.remove(activeClasses[0]); });
                    targetList.forEach(function(t){ t.classList.remove(activeClasses[1], activeClasses[2]); });

                }, false);

            }

            window.addEventListener('click', function(e) {

                if(triggers) { triggers.forEach(function(m){ m.classList.remove(activeClasses[0]); }); }
                if(targetList) { targetList.forEach(function(t){ t.classList.remove(activeClasses[1], activeClasses[2]); }); }

            }, false);

        };

    if(document.querySelector('.main-nav')) {

        w.addEventListener('load', init);
        w.addEventListener('resize', resize);

    }

}(window));
