/*
 * Product table
 */

(function (w, undefined) {

    var toggler = {
            el: {},
            classActive: 'active',
            init: function() {

                this.el.togglers = document.querySelectorAll('.ps__table__column__mobileTrigger');
                if(!this.el.togglers.length) { return; }

                this.addEvents();

            },
            addEvents: function() {

                [].forEach.call(this.el.togglers, function(trigger) {

                    var group = trigger.parentNode.parentNode.parentNode;
                    var allGroups = document.querySelectorAll('.ps__product__body'),
                        headerHeight = document.querySelector('.ps__product__head').offsetHeight;

                    if(group) {

                        trigger.addEventListener('click', function() {

                            [].forEach.call(allGroups, function(groups){

                                if (groups !== group) { groups.classList.remove('open'); }

                            });

                            group.classList.toggle('open');

                            window.scrollTo({
                                top: group.offsetTop - headerHeight - 55,
                                behavior: 'smooth'
                            });

                        }.bind(this), false);

                    }

                }.bind(this));

            }
        },
        sticky = {
            el: {},
            active: false,
            init: function() {

                this.el.head = document.querySelector('.ps__product__head');
                if (!this.el.head) { return; }

                this.verify();

                document.addEventListener('scroll', function(e) { this.verify(); }.bind(this), false);

            },
            verify: function() {

                var a = (this.el.head.getBoundingClientRect()).top === 0;

                if (a !== this.active) {

                    this.active = a;

                    if (this.active) {
                        this.el.head.classList.add('sticky');
                    } else {
                        this.el.head.classList.remove('sticky');
                    }

                }

            }
        },
        popups = {
            el: {},
            classes: {
                popupClass: 'visible',
                triggerClass: 'popup--visible'
            },
            init: function() {

                this.el.triggers = document.querySelectorAll('[data-popup]');
                if(!this.el.triggers.length) { return; }

                this.addEvents();

            },
            addEvents: function() {

                [].forEach.call(this.el.triggers, function(trigger) {

                    if (document.querySelector('body').classList.contains('no-touch')) {
                        trigger.addEventListener('mouseover', function(e) { this.show(trigger); }.bind(this), false);
                        trigger.addEventListener('mouseout', function(e) { this.hide(trigger); }.bind(this), false);
                    } else {
                        if (trigger.dataset.popupStyle === 'toggle') {
                            trigger.addEventListener('click', function(e) { this.toggle(trigger); }.bind(this), false);
                        } else if (trigger.dataset.popupStyle === 'toggle-touch' && window.outerWidth >= 768) {
                            trigger.addEventListener('click', function(e) { this.toggle(trigger); }.bind(this), false);
                        } else {
                            var close = document.querySelector(trigger.dataset.popup).querySelector('[data-popup-close]');
                            trigger.addEventListener('click', function(e) { this.show(trigger); }.bind(this), false);
                            close.addEventListener('click', function(e) { this.hide(trigger); }.bind(this), false);
                        }
                    }

                }.bind(this));

            },
            show: function(trigger) {

                var popup = document.querySelector(trigger.dataset.popup);
                if(!popup) { return; }

                trigger.parentNode.classList.add(popups.classes.triggerClass);
                popup.classList.add(popups.classes.popupClass);

            },
            hide: function(trigger) {

                var popup = document.querySelector(trigger.dataset.popup);
                if(!popup) { return; }

                trigger.parentNode.classList.remove(popups.classes.triggerClass);
                popup.classList.remove(popups.classes.popupClass);

            },
            toggle: function(trigger) {

                var popup = document.querySelector(trigger.dataset.popup);
                if(!popup) { return; }

                trigger.parentNode.classList.toggle(popups.classes.triggerClass);
                popup.classList.toggle(popups.classes.popupClass);

            }
        };

    w.addEventListener('load', function() {

        toggler.init();
        sticky.init();
        popups.init();

    });

}(window));
