/*
* @Author: Stijn Van Minnebruggen
* @Date:   2018-03-30 13:43:33
* @Last Modified by:   Stijn Van Minnebruggen
* @Last Modified time: 2018-03-30 14:10:51
*/

(function(w, undefined) {

	var d = w.document,
		init = function() {

			new w.Hero({
				wrapper: '.hero__inner',
				sections: '.hero__front__section',
				video: '.hero__back__video',
				sectionClass: 'hero__section--'
			});

		};

    w.addEventListener('load', init);

}(window));
