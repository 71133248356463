/*
* @Author: Jens K
* @Date:   2018-08-07 10:28:59
* @Last Modified by:   Jens K
* @Last Modified time: 2018-08-07 11:07:06
* /
(function (w, undefined) {

    var stories = {
        el: {},
        settings: {
            topicsCheckbox: '.stories-finder__topic__checkbox',
            tagList: '.stories-finder__topics__tags'
        },
        init: function () {

            stories.update();
            stories.disclaimer();
            stories.readmore();

        },
        update: function () {

            stories.el.topicsCheckbox = document.querySelectorAll(stories.settings.topicsCheckbox),
            stories.el.taglist = document.querySelector(stories.settings.tagList);

            [].forEach.call(stories.el.topicsCheckbox, function (chckbx) {



            });

        },
        disclaimer: function(){

            var disclaimer = document.querySelectorAll('.disclaimer__item');

            if (!disclaimer.length) { return; }

            [].forEach.call(disclaimer, function(_disclaimer){

                var trigger = _disclaimer.querySelector('.disclaimer__toggler');

                if (!trigger) { return; }

                trigger.addEventListener('click', function (e) {

                    e.preventDefault();
                    _disclaimer.classList.toggle('disclaimer--open');

                }, false);

            });

        },
        readmore: function(){

            var readmore = document.querySelectorAll('.disclaimer__readmore');

            if (!readmore.length) { return; }

            [].forEach.call(readmore, function(_readmore) {

                _readmore.addEventListener('click', function (e) {

                    e.preventDefault();
                    var target = document.querySelector(_readmore.dataset.readmore);

                    _readmore.classList.toggle('active');
                    target.classList.toggle('active');

                }, false);

            });

        }
    };

    w.addEventListener('load', stories.init());

}(window)); */
