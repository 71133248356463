/**
 * Portfolio
 */

(function(w) {

	"use strict";

	var portfolio = {
		el: {},
		settings: {
			el: {
				wrapper: '.portfolio__search',
				searchField: '.portfolio__search__field',
				searchButton: '.portfolio__search__button',
				clearButton: '.portfolio__search__clear',
				subForm: '.portfolio__search__subform',
				tagsForm: '.portfolio__search__tags',
				tagsKeeper: '.portfolio__search__tags__keeper',
				tagsBlocks: '.portfolio__search__tags__block',
				results: '.portfolio__results'
			},
			classList: {
				show: 'show',
				error: 'error',
				loader: 'loader',
				tag: 'portfolio__search__tags__keeper__tag',
				specialTag: 'portfolio__search__tags__keeper__specialtag'
			},
			currentBlock: -1
		},
		init: function() {

			portfolio.el.wrapper = document.querySelector(portfolio.settings.el.wrapper);
			if(!portfolio.el.wrapper) { return; }

			portfolio.el.searchField = document.querySelector(portfolio.settings.el.searchField);
			portfolio.el.searchButton = document.querySelector(portfolio.settings.el.searchButton);
			portfolio.el.searchButton.addEventListener('click', function(e) { e.preventDefault(); portfolio.search(); }, false);
			portfolio.el.clearButton = document.querySelector(portfolio.settings.el.clearButton);
			portfolio.el.clearButton.addEventListener('click', function(e) { e.preventDefault(); portfolio.clear(); }, false);

			portfolio.el.subForm = document.querySelector(portfolio.settings.el.subForm);
			portfolio.el.tagsForm = document.querySelector(portfolio.settings.el.tagsForm);
			portfolio.el.tagsBlocks = document.querySelectorAll(portfolio.settings.el.tagsBlocks);
			portfolio.el.tagsKeeper = document.querySelector(portfolio.settings.el.tagsKeeper);

			portfolio.el.results = document.querySelector(portfolio.settings.el.results);

		},
		search: function() {

			if(portfolio.el.searchField.value.trim() === '') {

				portfolio.el.searchField.classList.add(portfolio.settings.classList.error);

			} else {

				var tags = portfolio.el.searchField.value.trim().split(' ');
				[].forEach.call(tags, function(tag) { portfolio.addTag(tag); });

				portfolio.el.searchField.classList.remove(portfolio.settings.classList.error);
				portfolio.el.subForm.classList.remove(portfolio.settings.classList.show);
				portfolio.el.tagsForm.classList.add(portfolio.settings.classList.show);
				portfolio.el.clearButton.classList.add(portfolio.settings.classList.show);
				portfolio.el.searchField.value = '';

				if(!portfolio.triggered) {

					var links = portfolio.el.tagsForm.querySelectorAll('a');
					if(links.length) {

						portfolio.triggered = true;

						[].forEach.call(links, function(a) {

							a.addEventListener('click', function(e) {

								var special = this.dataset.special || false;

								e.preventDefault();
								portfolio.addTag(a.innerText, special);

							}, false);

						});

					}

				}

			}

		},
		addTag: function(tag, special) {

			var link = document.createElement('a');

			link.classList.add(portfolio.settings.classList.tag);

			if(special) {

				link.classList.add(portfolio.settings.classList.specialTag);
				link.dataset.special = special;

				portfolio.tagsBlockUpdate(1);

			}

			link.innerText = tag;
			link.addEventListener('click', function(e) {

				var t = this;

				e.preventDefault();

				if(t.classList.contains(portfolio.settings.classList.specialTag)) {

					portfolio.tagsBlockUpdate(-t.dataset.special);

				}

				t.parentNode.removeChild(t);

				portfolio.validateTags();

			}, false);

			portfolio.el.tagsKeeper.appendChild(link);
			portfolio.showResults();

		},
		validateTags: function() {

			if(portfolio.el.tagsKeeper.innerHTML === '') {

				portfolio.el.results.classList.remove(portfolio.settings.classList.show);
				portfolio.el.subForm.classList.add(portfolio.settings.classList.show);
				portfolio.el.tagsForm.classList.remove(portfolio.settings.classList.show);
				portfolio.el.clearButton.classList.remove(portfolio.settings.classList.show);

				[].forEach.call(portfolio.el.tagsBlocks, function(block) { block.classList.remove(portfolio.settings.classList.show); });
				portfolio.settings.currentBlock = -1;

			}

		},
		clear: function() {

			portfolio.el.tagsKeeper.innerHTML = '';
			portfolio.validateTags();

		},
		tagsBlockUpdate: function(add) {

			[].forEach.call(portfolio.el.tagsBlocks, function(tb) { tb.classList.remove(portfolio.settings.classList.show); });

			portfolio.settings.currentBlock += add;

			if(portfolio.el.tagsBlocks[portfolio.settings.currentBlock]) {

				portfolio.el.tagsForm.classList.add(portfolio.settings.classList.show);
				portfolio.el.tagsBlocks[portfolio.settings.currentBlock].classList.add(portfolio.settings.classList.show);

			} else {

				portfolio.el.tagsForm.classList.remove(portfolio.settings.classList.show);

			}

		},
		showResults: function() {

			if(portfolio.el.results.classList.contains(portfolio.settings.classList.loader)) { return; }

			portfolio.el.results.classList.add(portfolio.settings.classList.loader);
			portfolio.el.results.classList.remove(portfolio.settings.classList.show);

			setTimeout(function() {

				portfolio.el.results.classList.remove(portfolio.settings.classList.loader);
				portfolio.el.results.classList.add(portfolio.settings.classList.show);

			}, 1000);

		}
	};

	w.addEventListener('load', portfolio.init);

}(window));
