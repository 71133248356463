/*
* @Author: Jens K
* @Date:   2018-05-15 10:31:24
* @Last Modified by:   Jens K
* @Last Modified time: 2018-05-15 16:45:29
* */

(function(w) {

    "use strict";

    var lang = {
        init: function() {

            lang.scrollTo();

        },
        scrollTo: function() {

            var scroller = document.getElementsByClassName('optimal-language__other');

            if (!scroller.length) { return; }

            scroller[0].addEventListener('click', function(e){

                var target = document.querySelector(this.getAttribute('href')).getBoundingClientRect();

                e.preventDefault();

                window.scrollTo({
                    top: target.top - 100,
                    left: 0,
                    behavior: 'smooth'
                });

            }, false);

        }
    };

    w.addEventListener('load', lang.init);

}(window));
