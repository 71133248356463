/*
* @Author: Stijn Van Minnebruggen
* @Date:   2018-04-09 14:38:00
* @Last Modified by:   Jens K
* @Last Modified time: 2018-07-02 15:05:07
*/

(function(w, undefined) {

	var init = function() {

			footer.init();

		},
		footer = {
			el: {},
			classList: {
				list: 'footer__directory__list',
				open: 'footer__directory__column--open',
				link: 'footer__directory__title'
			},
			init: function() {

				footer.el.lists = document.querySelectorAll('.' + footer.classList.list);
				[].forEach.call(footer.el.lists, function(list) {

					var column = list.parentNode;
					column.querySelector('.' + footer.classList.link).addEventListener('click', function(e) {

						if(column.classList.contains(footer.classList.open)) {
							column.classList.remove(footer.classList.open);
						} else {
							column.classList.add(footer.classList.open);
						}

					}, false);

				});

			}
		};

	w.addEventListener('load', init);

}(window));
