/*
* @Author: Jens K
* @Date:   2018-08-07 11:25:40
* @Last Modified by:   Jens K
* @Last Modified time: 2018-08-07 11:31:27
*/
(function(w, undefined) {

    var helpers = {
        init: function() {

            helpers.isTouch();
            helpers.scrollVisible();

        },
        isTouch: function() {

            function is_touch_device() { return 'ontouchstart' in window || navigator.maxTouchPoints; };

            if (is_touch_device()) {

                document.querySelector('body').classList.add('touch');

            } else {

                document.querySelector('body').classList.add('no-touch');

            }

        },
        scrollVisible: function() {

            var windowWidth = window.innerWidth,
                scrollWidth = document.documentElement.clientWidth;

            if (scrollWidth < windowWidth) {

                document.querySelector('body').classList.add('scroll-visible');

            }

        }
    };

    w.addEventListener('load', helpers.init());

}(window));
