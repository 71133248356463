/*
* @Author: Jens K
* @Date:   2018-08-06 15:06:34
* @Last Modified by:   Jens K
* @Last Modified time: 2018-08-06 17:21:28
*/


(function(w, undefined) {

    var instaFeed = {
        el: {},
        // settings: {
        //     clientID: '2228339f328f4e07adc10baa4fcef90a'
        // },
        results: [],
        init: function() {

            instaFeed.getFeed();

        },
        getFeed: function() {

            var xhr = new XMLHttpRequest(),
                url = 'https://api.instagram.com/v1/users/self/media/recent/?access_token=2097703799.2228339.50eb2dd3c70a4fe4a1700ae29dda8bc8&count=3';

            xhr.onload = function() {

                if(xhr.status !== 200) {
                    throw new Error("Woops! Something went wrong while fetching instagram feed.");
                    return;
                }

                instaFeed.results = JSON.parse(xhr.responseText);

            };

            xhr.open('GET', url);
            xhr.send();

        }
    };

    if(document.querySelector('.instagram-feed')) {

        w.addEventListener('load', instaFeed.init());

    }

}(window));
