/**
 * FAQ
 *
 */

(function(w) {

	var FAQ = function(el) {

		this.settings = {
			el: {
				wrapper: el
			},
			classList: {
				faqs: 'faqs__faq',
				question: 'faqs__faq__question',
				answer: 'faqs__faq__answer',
				visible: 'visible'
			}
		};

		this.init();

	};

	FAQ.prototype.init = function() {

		this.settings.el.questions = this.settings.el.wrapper.querySelectorAll('.' + this.settings.classList.question);
		if(!this.settings.el.questions) { return; }

		[].forEach.call(this.settings.el.questions, function(question) {

			var t = this;

			question.addEventListener('click', function(e) {

				e.preventDefault();
				t.toggle(question);

			}.bind(t), false);

		}.bind(this));

	};

	FAQ.prototype.toggle = function(question) {

		var faq = question.closest('.' + this.settings.classList.faqs) || this._findAncestor(question, this.settings.classList.faqs) || false;
		if(!faq) { return; }

		window.dataLayer = window.dataLayer || [];

		if(faq.classList.contains(this.settings.classList.visible)) {
			faq.classList.remove(this.settings.classList.visible);
			window.dataLayer.push({
				"event": "gaEvent",
				"eventCategory": "faq",
				"eventAction": "fold",
				"eventLabel": question.innerText,
				"eventValue": undefined,
				"eventNonInteraction": false
			});
		} else {
			faq.classList.add(this.settings.classList.visible);
			window.dataLayer.push({
				"event": "gaEvent",
				"eventCategory": "faq",
				"eventAction": "expand",
				"eventLabel": question.innerText,
				"eventValue": undefined,
				"eventNonInteraction": false
			});
		}

	};

	FAQ.prototype._findAncestor = function(el, className) {

		while((el = el.parentElement) && !el.classList.contains(className));
		return el;

	};

	w.addEventListener('load', function() {

		var faqs = document.querySelectorAll('.faqs');
		if(!faqs.length) { return; }

		[].forEach.call(faqs, function(el) { new FAQ(el); });

	});

}(window));
