/*
* GTM Events
*/
(function() {

    window.gtm = function() {

        var gtmTriggers = document.querySelectorAll('[data-gtm-category]');
        if (!gtmTriggers.length) { return; }

        [].forEach.call(gtmTriggers, function (gtmTrigger) {

            if (gtmTrigger.dataset.gtmType === 'focusout') {

                gtmTrigger.addEventListener('focusout', function(){

                    if (this.value.length) { pushGtm(gtmTrigger); }

                });

            } else if (gtmTrigger.dataset.gtmType !== 'form') {

                gtmTrigger.addEventListener('click', function(){

                    pushGtm(gtmTrigger);

                }, false);

            }

        });

        function pushGtm(gtmTrigger) {

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "gaEvent",
                "eventCategory": gtmTrigger.dataset.gtmCategory || "",
                "eventAction": gtmTrigger.dataset.gtmAction || "",
                "eventLabel": gtmTrigger.dataset.gtmLabel || "",
                "eventValue": gtmTrigger.dataset.gtmValue || undefined,
                "eventNonInteraction": gtmTrigger.dataset.gtmNoninteraction || false
            });

        }

    };

    window.addEventListener('load', new gtm() );

}());
