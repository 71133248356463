/*
* Social share
*/

(function (w, undefined) {

    var social = {
        settings: {
            facebook: '.fb-share',
            linkedin: '.in-share',
            copyUrl: '.link-share'
        },
        init: function () {

            social.facebook();
            social.twitter();
            social.linkedin();
            social.copyUrl();

        },
        facebook: function() {

            var fbShare = document.querySelectorAll(social.settings.facebook);
            if (!fbShare.length) { return; }

            if (!w.fbAppId) { console.error('FB App Id not defined.'); return; }

            w.fbAsyncInit = function() {
                FB.init({
                appId            : w.fbAppId,
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v3.1'
                });
            };

            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));

            [].forEach.call(fbShare, function(_fbShare){

                _fbShare.addEventListener('click', function (e) {

                    e.preventDefault();
                    FB.ui({
                        method: 'share',
                        display: 'popup',
                        href: window.location.href,
                    }, function(response){});

                });

            });

        },
        twitter: function () {

            w.twttr = (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0],
                    t = window.twttr || {};
                if (d.getElementById(id)) return t;
                js = d.createElement(s);
                js.id = id;
                js.src = "https://platform.twitter.com/widgets.js";
                fjs.parentNode.insertBefore(js, fjs);

                t._e = [];
                t.ready = function(f) {
                    t._e.push(f);
                };

                return t;
            }(document, "script", "twitter-wjs"));

        },
        linkedin: function() {

            var inShare = document.querySelectorAll(social.settings.linkedin);

            if (!inShare.length) { return; }

            [].forEach.call(inShare, function(_inShare){

                _inShare.addEventListener('click', function (e) {

                    e.preventDefault();
                    window.open('http://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent(window.location.href), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0');

                });

            });

        },
        copyUrl: function() {

            var linkCopy = document.querySelectorAll(social.settings.copyUrl);

            if (!linkCopy.length) { return; }

            [].forEach.call(linkCopy, function(_linkCopy){

                var modal = new Modal({
                    content: '<p>' + _linkCopy.dataset.modal + '</p>'
                });

                _linkCopy.addEventListener('click', function (e) {

                    e.preventDefault();

                    var el = document.createElement('textarea');
                    el.value = window.location.href;
                    el.setAttribute('readonly', '');
                    el.style.position = 'fixed';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);

                    modal.open();

                });

            });

        }
    };

    w.addEventListener('load', social.init());

}(window));
