/*
 * Forms and fields
 *
 */

(function(w, undefined) {

	var fields = {
			el: {},
			init: function() {

				fields.el.fields = document.querySelectorAll('.form__field__labeled .fld, .form__fld, .form__txta, .form__slct');
				if(fields.el.fields.length) { fields.activate(); }

			},
			activate: function() {

				[].forEach.call(fields.el.fields, function(fld) {

					fields.updateLabel(fld, fld.value);
					fld.addEventListener('change', function(e) { fields.updateLabel(this, e.target.value); }, false);

				});

			},
			updateLabel: function(fld, value) {

				if(!value || !value.length) {

					fld.classList.remove('lbl-open');
					fld.classList.remove('active');

				} else {

					fld.classList.add('lbl-open');
					fld.classList.add('active');

				}

			}
		},
        customSelect = {
            el: {},
            classList: {
                select: 'form__slct',
                filter: 'slct__filter',
                active: 'select__open',
                opened: 'select__opened',
                selected: 'selected'
            },
            init: function() {

                if(window.innerWidth >= 768) { customSelect.create(); }

            },
            create: function() {

                customSelect.el.select = document.querySelectorAll('.' + customSelect.classList.select);
                if(!customSelect.el.select.length) { return; }

                [].forEach.call(customSelect.el.select, function(slct) {

                    var filter = false,
                        opens = 'bs__custom-select__bottom';

                    if(slct.classList.contains(customSelect.classList.filter)) {

                        filter = true;
                        filterPlaceholder = slct.dataset.filtertext;

                    }

                    if(slct.dataset.opens == 'top') {

                        opens = 'bs__custom-select__top';

                    }

                    slct.parentNode.classList.add('bs__custom-select__hidden');

                    var html = '', cs = document.createElement('div');

                    cs.setAttribute('class', 'bs__custom-select');

                    customSelect.el.defaultVal = slct.parentNode.querySelector('span').innerHTML;

                    html += '<a href="#" class="bs__custom-select__trigger">';
                        html += '<span class="bs__custom-select__label">' + slct.parentNode.querySelector('span').innerHTML + '</span>';
                        html += '<strong class="bs__custom-select__selected"></strong>';
                    html += '</a>';
                    html += '<strong class="form__error">' + slct.parentNode.querySelector('.form__error').innerHTML + '</strong>';
                    html += '<div class="bs__custom-select__options ' + opens + '">';
                        if(filter) { html += '<div class="bs__custom-select__filter"><input type="text" placeholder="' + filterPlaceholder + '" /></div>' }
                        html += '<ul class="bs__custom-select__options__list">';

                        for(var i = 0, x = slct.options.length; i < x; i++){

                            if(slct.options[i].text) {

                                html += '<li data-value="' + slct.options[i].value + '">' + slct.options[i].text + '</li>';

                            }

                        }

                        html += '</ul>';
                    html += '</div>';

                    cs.innerHTML = html;
                    slct.parentNode.parentNode.appendChild(cs);

                    customSelect.update(slct, filter);

                    if(filter) {

                        var _filter = slct.parentNode.parentNode.querySelector('.bs__custom-select__filter input');
                        _filter.addEventListener('keyup', function(){

                            customSelect.filter(slct);

                        });

                    }

                });

            },
            update: function(slct, filter) {

                var wrapper = slct.parentNode.parentNode.querySelector('.bs__custom-select'),
                    trigger = slct.parentNode.parentNode.querySelector('.bs__custom-select__trigger'),
                    selected = slct.parentNode.parentNode.querySelector('.bs__custom-select__selected'),
                    target = slct.parentNode.parentNode.querySelector('.bs__custom-select__options'),
                    _filter = slct.parentNode.parentNode.querySelector('.bs__custom-select__filter input'),
                    options = slct.options,
                    customOptions = target.querySelectorAll('li');

                trigger.addEventListener('click', function(e){

                    e.preventDefault();
                    e.stopPropagation();

                    if(wrapper.classList.contains(customSelect.classList.active)) {

                        wrapper.classList.remove(customSelect.classList.active);
                        if(selected.innerHTML == '') { wrapper.classList.remove(customSelect.classList.opened); }

                    } else {

                        wrapper.classList.add(customSelect.classList.active);
                        wrapper.classList.add(customSelect.classList.opened);

                        if(filter) { _filter.focus(); }

                    }

                }, false);

                window.addEventListener('click', function(e) {

                    if(e.target.closest('.bs__custom-select')) { return };

                    wrapper.classList.remove(customSelect.classList.active);
                    if(selected.innerHTML == '') { wrapper.classList.remove(customSelect.classList.opened); }

                });

                [].forEach.call(customOptions, function(option) {

                    option.addEventListener('click', function(){

                        [].forEach.call(customOptions, function(opt) { opt.classList.remove(customSelect.classList.selected); });
                        this.classList.add(customSelect.classList.selected);

                        var val = this.dataset.value, text = this.innerHTML;

                        selected.innerHTML = text;

                        [].forEach.call(options, function(slctd) {

                            if(slctd.value == val) {

                                slctd.selected = true;

                            }

                        });

                        wrapper.classList.remove(customSelect.classList.active);

                    });

                });

            },
            filter: function(slct) {

                var filter = slct.parentNode.parentNode.querySelector('.bs__custom-select__filter input'),
                    _value = filter.value.toUpperCase(),
                    options = slct.parentNode.parentNode.querySelectorAll('.bs__custom-select__options__list li');

                for (i = 0; i < options.length; i++) {

                    if(options[i].innerHTML.toUpperCase().indexOf(_value) > -1) {

                        options[i].style.display = "";

                    } else {

                        options[i].style.display = "none";

                    }

                }

            }
        },
		forms = {
			el: {},
			classList: {
				autoValidateTrigger: 'autoValidate',
				fldError: 'error'
			},
			init: function() {

				forms.el.frms = document.querySelectorAll('form.' + forms.classList.autoValidateTrigger);
				forms.activate(forms.el.frms, 'submit');

				forms.el.btns = document.querySelectorAll('button.' + forms.classList.autoValidateTrigger + ', a.' + forms.classList.autoValidateTrigger);
				forms.activate(forms.el.btns, 'click');

			},
			activate: function(els, action) {

				if(!els.length) { return; }
				[].forEach.call(els, function(el) {

					el.addEventListener(action || 'submit', function(e) {

						var frm = el.nodeName.toLowerCase() === 'form' ? el : el.closest('form') || forms.findAncestorForm(el);
						if(!forms.formIsValid(frm)) {

							e.preventDefault();

						}

					}, false);

				});

			},
			formIsValid: function(frm) {

				var flds = frm.querySelectorAll('input, textarea, select');
                var valid = true;

				[].forEach.call(flds, function(fld) {

					if(fld.required) {

                        var pattern = fld.getAttribute('pattern') || fld.pattern || false,
                            parent = fld.closest('.error-container'),
                            errDefault = parent.querySelector('.error__default'),
                            errMail = parent.querySelector('.error__mail');

                        if(fld.value === null || fld.value === '') {

                            forms.isFalse(fld, errDefault);
                            valid = false;

                        } else if (fld.type === 'email' && !forms.isEmail(fld.value)) {

							forms.isFalse(fld, errMail);
                            valid = false;

						} else if (pattern && !forms.isPattern(fld.value, pattern)) {

							forms.isFalse(fld, errDefault);
							valid = false;

						} else if (fld.type === 'checkbox' && !fld.checked) {

                            forms.isFalse(fld, errDefault);
                            valid = false;

                        } else {

                            forms.isTrue(fld);

                        }

					}

				});

				if(valid) {
                    frm.classList.remove(forms.classList.fldError);
                    if (frm.dataset.gtmType === 'form') {
                        w.dataLayer = w.dataLayer || [];
                        w.dataLayer.push({
                            "event": "gaEvent",
                            "eventCategory": frm.dataset.gtmCategory || "",
                            "eventAction": frm.dataset.gtmAction || "",
                            "eventLabel": frm.dataset.gtmLabel || "",
                            "eventValue": gtmTrigger.dataset.gtmValue || undefined,
                            "eventNonInteraction": falgtmTrigger.dataset.gtmNoninteraction || false
                        });
                    }
				} else {
					frm.classList.add(forms.classList.fldError);
				}

				return valid;

			},
			isPattern: function(value, pattern) {

				var re = new RegExp(pattern, 'gim');
				return re.test(value);

			},
			isEmail: function(value) {

				return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

			},
			isFalse: function(fld, err) {

                var error = fld.closest('.error-container').querySelectorAll('.form__error');
                [].forEach.call(error, function(_error) { _error.style.display = 'none'; });

                fld.parentNode.classList.add(forms.classList.fldError);
                err.style.display = 'block';

			},
			isTrue: function(fld, err) {

                var error = fld.closest('.error-container').querySelectorAll('.form__error');
                [].forEach.call(error, function(_error) { _error.style.display = 'none'; });

                fld.parentNode.classList.remove(forms.classList.fldError);

			},
			findAncestorForm: function(el) {

				while((el = el.parentElement) && el.nodeName.toLowerCase() !== 'form');
				return el;

			}
		};

	w.addEventListener('load', function() {

		fields.init();
		forms.init();
        customSelect.init();

	});

}(window));
